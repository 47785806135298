

@import url("https://fonts.cdnfonts.com/css/myriad-pro");
@import url("./protokoll/ProtokollObjectOwner.css");
@import url("./protokoll/ProtokolSteps.css");
@import url("./protokoll/ProtokollType.css");
@import url("./authentication/ForgetPassword.css");
@import url("./authentication/ForgetPassword.css");
@import url("./authentication/Login.css");
@import url("./Side.css");
/* @import url('./basket/Sidebar.css'); */
@import url("./Dashboard.css");
@import url("./contract/selectProduct.css");
@import url("./contract/contractSteps.css");
@import url("./protokoll/ProtokollOvwerviewRooms.css");
@import url("./protokoll/Roomdetails.css");
@import url("./protokoll/ProtokollRenter.css");
@import url("./protokoll/ProtkollCounter.css");
@import url("./protokoll/ZahlertypList.css");
@import url("./protokoll/ProtokollKeys.css");
@import url("./protokoll/ProtokollOvwerview.css");

@import url("./contract/gasOverview.css");
@import url("./contract/counterInfo.css");
@import url("./contract/card.css");

@import url("./kaution/kaution_Selector.css");

@import url("./Properties/Create_property.css");
@import url("./Properties/RealEstate.css");
@import url("./Properties/Appartements.css");

/* #### Generate font-face Myriad Pro #### */

@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MYRIADPRO-BOLD.woff") format("woff");
}

:root {
  --thm-font: "Myriad Pro Regular", sans-serif;
  --thm-font-bold: "Myriad Pro Regular", sans-serif;
  --heading-font: "Myriad Pro Regular", sans-serif;
  --primary: #62ab12 !important;
  --primary-rgb: 254, 0, 64;
  --black: #182c44;
  --black-rgb: 24, 44, 68;
  --text: #888888;
  --white: #fff;
  --black-bg: #0a1829;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

.bg-color-home {
  font-size: 16px;
  line-height: 1.87;
  color: var(--text);
  font-family: var(--thm-font);
  background-image: url("./../Icons/gruppe-494@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #00447b;
  /* 100% of viewport height */
  width: 100%;
  height: 100%;
  /* 100% of viewport width */
}
.bg-color-home1 {
  font-size: 16px;
  line-height: 1.87;
  color: var(--text);
  font-family: var(--thm-font);
  width: 100%;
  height: 100%;
}


/* Webkit scrollbar styles */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
  cursor: pointer;
}

/* Firefox and Edge scrollbar styles */
body {
  overflow: initial !important;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

body::-webkit-scrollbar {
  width: 7px;
}

.forgetPass:hover {
  color: #0056b3 !important
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
}

.page-wrapper {
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

p {
  line-height: 32px;
  font-size: 18px;
  hyphens: auto;
}

.btn,
.btn:focus,
button,
button:focus {
  outline: none !important;
}

.btn {
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 7px;
}

.btn:hover {
  outline: none;
  text-decoration: none;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-30 {
  font-size: 30px;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

/*------------------------------------------------
--------------------------
3. Upper Header
--------------------------
------------------------------------------------*/
.layout-container {
  display: flex;
  flex-direction: row;
}

.menu-sidebar {
  flex: 1;
  margin-left: 20%;
}

.margin-navbar {
  margin: 0px;
}

.upper-header {
  background-color: #00457c;
  width: 100%;
}

@media (min-width: 768px) {
  .upper-header {
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .upper-header .container {
    display: flex;
    justify-content: flex-end;
  }
}

.upper-header .side-menu-toggler {
  font-size: 20px;
  cursor: pointer;
  color: var(--black);
}

@media (min-width: 768px) {
  .upper-header .side-menu-toggler {
    margin-left: 15px;
  }
}

@media (min-width: 1200px) {
  .upper-header .side-menu-toggler {
    display: none;
  }
}

.upper-header .header-infos {
  display: none;
}

@media (min-width: 768px) {
  .upper-header .header-infos {
    display: flex;
    align-items: center;
  }
}

.upper-header .header-info {
  padding-left: 41px;
  position: relative;
}

.upper-header .header-info+.header-info {
  margin-left: 30px;
}

@media (min-width: 992px) {
  .upper-header .header-info+.header-info {
    margin-left: 50px;
  }
}

.upper-header .header-info>i {
  font-size: 28px;
  color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
}

.upper-header .header-info h3 {
  margin: 0;
  color: var(--black);
  font-size: 16px;
  font-weight: bold;
  font-family: var(--thm-font);
  margin-bottom: 3px;
}

.upper-header .header-info p {
  margin: 0;
  color: var(--black);
  font-size: 14px;
  font-family: var(--thm-font);
}

.upper-header .header-info h3 a {
  color: inherit;
  transition: 500ms;
}

.upper-header .header-info h3 a:hover {
  color: var(--primary);
}

/* preloader */

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* scroll to top */

.scroll-to-top {
  width: 45px;
  height: 45px;
  background: #009fe3;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 99;
  text-align: center;
  transition: all 0.4s ease;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 30px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: #333;
}

.scroll-to-top:hover i {
  color: #fff;
}

.loader__centred {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  height: 200px;
  width: 100%;

}

.checkbox-champ {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.checkbox-champ .hauptmieterH3 {
  margin-bottom: 0px !important;
}
.wohnungsdaten{
  border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.sub_wohnungsdaten-border{
  border-top-left-radius: 5px;

}
.h-auto{
  height: auto !important;
}
#keys .wohnungsdaten h3
{
  font-size: 14px !important;
}
.neuer svg{
  width: 27px !important;
  height: 30px !important;
}
.sub_wohnungsdaten svg{
  width: 27px !important;
  height: 30px !important;
}
.hauptmieterH3{
  font-size: 16px;
}

.checkbox-champ-first {
  padding: 10px 30px;
}

.OvwerviewGlobalButtonSUP button {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 63px;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 1);
  opacity: 1;
  background-color: rgba(98, 171, 18, 1);
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  font-family: "Myriad Pro", sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.textfieldsdateplzObOwBgnone {
  display: flex;
  padding: 5px 20px;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}

.MuiDataGrid-footerContainer {
  display: none !important;
}

.column-container img {
  width: 21px;
  margin-right: 5px;
  height: 37px !important;
}

.column-content {

  cursor: pointer;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  line-height: 26px;
}

.icon-strom {

  filter: none !important;
}

.item-tickett {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.log-tickets {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.randomCodePromo {
  color: #00447b;
  cursor: pointer;
}

.search-contain {
  display: flex;
  justify-content: end;
}

.filter-box {
  padding: 10px;
  background: rgb(0, 68, 123);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-box span {
  color: #fff
}

.filter-box .MuiGrid-root {
  text-align: center;
}
.filter-box label + .MuiInput-formControl{
  margin: inherit !important;
}
.filter-box label {
  color: #333;
}

.filter-box .MuiInputBase-root {
  height: 56px;
}

.filter-box .MuiFormLabel-root {
  font-size: 16px;
  margin-bottom: 0px !important;
}

.filter-box .label-box {
  margin-left: 15px;
  margin-top: 0px !important;
}

.filter-box .input-box {
  height: 56px;
  background-color: #fff;
  border-radius: 5px;
}


.filter-box .select-sort {
  padding-left: 3px;
  padding-top: -4px;
  margin-top: 0px;
}

.filter-box .select-sort.MuiInput-underline::before {
  border: none !important;
}

.filter-box .select-sort.MuiInput-underline::after {
  border: none !important;
}
.counter_icon_delete{
z-index: 5 !important;
}
.counter_icon_delete svg{
right: 0px !important;
}

.file-block {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid green;
}

.file-block p {
  padding-top: 10px;
}

.file-block svg {
  color: red;
}

.item-tickett {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-tickett span {
  display: flex;
  justify-content: start;
  width: 100%;
  text-align: left;
}

.item-tickett div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  text-align: left;
}

.log-tickets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.log-tickets span {
  font-weight: 600;
  color: #009fe3;
}

.green-btn,
.green-btn:hover {
  background-color: #62ab12;
}

.checkbox-champ {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.checkbox-champ-first {
  padding: 10px 30px;
}

.joEditor::placeholder {
  text-transform: lowercase;
}

.room-type .MuiInputBase-input[disabled] {
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.valueselected-deffect .MuiInputBase-input[disabled] {
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.randomCodePromo {
  cursor: pointer;
  color: #00447b;
}

.randomCodePromo:hover {
  border-bottom: 1px solid #00447b;
}

.dialog {
  display: none;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 999;
}

.show {
  display: flex;
}
.dialogCounter{
  padding: 0px !important;
  overflow-x: hidden !important;
}
.dialogPromocode{
  padding: 0px 15px !important;
  overflow: hidden !important;
}
.dialogPromocode .hrOvwerview:last-child{
  display: none;
}
.dialog-content {
  /* position: relative; */
  /* flex: 1; */
  /* overflow-y: auto;  */
}
.Promocode .close-icon {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 43px;
}
.close-icon svg{
  font-size: 1.7rem !important;
}
.flex-align-room.kaution-option {
  justify-content: start !important;
}

.FieldsAutocomplete {
  flex: 1;
  /* Ensure the FieldsAutocomplete component takes up all available height */
}

.OvwerviewGlobalButtonSUP button {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 63px;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 1);
  opacity: 1;
  background-color: rgba(98, 171, 18, 1);
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  font-family: "Myriad Pro", sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.textfieldsdateplzObOwBgnone {
  display: flex;
  padding: 5px 20px;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}

.fm-class-title h3,
.fm-class-title h2 {
  font-family: "Myriad Pro Bold" !important;
}

.kommanta {
  margin-bottom: 10px;
}

.kommanta:last-child {
  margin-bottom: 0;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  height: 100%;
}

.boderRigtAdd {
  border-top-right-radius: 5px;
}

.MuiMenuItem-root {
  color: inherit !important;
}
.stepskaution .MuiStepper-horizontal{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 70px
}
.read-more-message:hover{
  border-bottom: 1px solid #009fe3;
  width: fit-content;
}
.promocode .MuiFormLabel-root{
  color: #717275;
}
.item-tickett span a {
  color: #000;
  cursor: pointer;
}
/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 17px;
} */

/* *::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
} */

*::-webkit-scrollbar-thumb {
  display: none;
  background-color: #00457c !important;
  border-radius: 0px !important;
}

/* ::-webkit-scrollbar-thumb:hover {
  box-shadow: ;
} */
.padding-center1{ 
  padding: 10px 20px 10px;
  align-items: center;
  height: 100%;}
  /* Custom scrollbar styles for react-custom-scrollbars */
.hulk-scroll .scrollbar-track {
  background: #f1f1f1;
}

.hulk-scroll .scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.hulk-scroll .scrollbar-thumb:hover {
  background: #555;
}
.hulk-scroll > div:nth-of-type(3) > div {
  background-color: rgba(0, 69, 124, 1) !important;
}
/* .icon-bewohnerButton{
  position: initial !important;
}  */
.New-promocode svg {
  width: 30px !important;
  height: 30px !important;
  right: 10px !important;
  top: 9px !important;
}
.card-details .LButtonsBeschreibung .Loschen-button svg{
  right: 8px !important;
}
.Room-detail .WeiterButton {
  width: 250px;
}
.Room-detail button.WeiterButton .MuiSvgIcon-root {
  top: 17px;
}
 .bewohnerAert .swal-button--confirm, .bewohnerAert .swal-button--confirm:hover{
background-color:rgba(98, 171, 18, 1) ;
color: #fff;
}
.bewohnerAert .swal-button--cancel,.bewohnerAert .swal-button--cancel:hover{
color: #fff;
background-color:  #D00000;
} 
.deleteBewohner button svg {
  top: -20px;
}
.button-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust width */
  height: 40px; /* Adjust height */
  border-radius: 50%; /* Make it circular */
  background-color: transparent;
  border: 4px solid rgba(0, 159, 227, 1);
  cursor: pointer; /* Pointer cursor on hover */
}
.button-container svg{
  right: 10px;
  top: 7px;
  color:rgba(0, 159, 227, 1) ;
}
.navigateFromForm{
  position: absolute;
  left: 5px;
}
.navigateFromForm .button-container {
  width: 30px; /* Adjust width */
  height: 30px; /* Adjust height */
  /* background-color: rgba(0, 159, 227, 1); */
  border: none;
}
.navigateFromForm .button-container svg {
  right: 3px;
  top: 2px;
  color: #fff;
  font-size: 26px;
}
.filter-icon-area{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  max-width: 50px !important;
  margin-left: 2px;
  border-radius: 5px;
}
.filter-icon-area :hover{
  cursor: pointer;
}
.filter-icon-area svg{color:#009fe3}
.Weiter .NavigationBottom {
  border-radius: 26px 26px 26px 26px;
  background-color: transparent;
  color: rgba(0, 159, 227, 1);
  text-align: center;
  text-decoration: none;
  border: 2px solid rgba(0, 159, 227, 1);
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 10px;
  font-weight: 600;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  width: 100px;
}
.Weiter .NavigationBottom svg{
  fill: rgba(0, 159, 227, 1) !important;
}